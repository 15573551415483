import React, { useState } from "react";
import {
  FaArrowRight,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaRegUser,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HiOutlineSwitchVertical } from "react-icons/hi";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { PiAirplaneTakeoffFill } from "react-icons/pi";
import { ImPriceTag } from "react-icons/im";
import { places } from "../../Container/Data/Place";
import { RiCustomerService2Line } from "react-icons/ri";
import Footer from "../../Component/common/Footer";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import LogoWhite from "../../assets/images/logo-white.png";
import LogoBlack from "../../assets/images/logo-black.png";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";
import { destinations } from "../../Container/Data/destination";
import { MdClose } from "react-icons/md";
import { PiPhoneCallFill } from "react-icons/pi";
import axios from "axios";
import { months } from "../../Container/Data/General";
import { MdOutlineClose } from "react-icons/md";
import Banner from "../../assets/images/banner.png";
import CallBanner from "../../assets/images/Call for booking.png";
import Custom from "../../assets/images/custom.png";
import Plane from "../../assets/images/plane.png";
import { motion } from "framer-motion";
import Booking from "../../assets/images/book.jpg";
import "./style.css";

function Home() {
  const [airPortSearch, setAirPortSearch] = useState([]);
  const [departureSelected, setDepatureSelected] = useState({
    title: "New York",
    slug: "NYC",
  });
  const [ariveSelected, setAriveSelected] = useState({
    title: "Los Angeles",
    slug: "LAX",
  });

  const [handleShowDeparture, setHandleShowDepature] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [error, setError] = useState({
    depature: false,
    arive: false,
    date: false,
    passanger: false,
  });
  const [passanger, setPassanger] = useState({
    adult: 0,
    child: 0,
    infants: 0,
  });
  const [showPassager, setShowPassager] = useState(false);
  const [handleShowArive, setHandleShowArive] = useState(false);
  const [selectedDate, setSelectedDate] = useState("2024-08-14");
  const [selectedReturnDate, setSelectedReturnDate] = useState("2024-08-20");

  const [quality, setQuality] = useState([
    {
      title: "Handpicked Destinations",
      icon: PiAirplaneTakeoffFill,
      description:
        "Our strict screening process means you’re only seeing the best quality treks.",
    },
    {
      title: "Best Price Guaranteed",
      icon: ImPriceTag,
      description:
        "Our Best Price Guarantee means that you can be sure of booking at the best rate.",
    },
    {
      title: "24/7 Customer Service",
      icon: RiCustomerService2Line,
      description:
        "Our customer are standing by 24/7 to make your experience incredible.",
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [tripType, setTripType] = useState("one-way");
  const [showHeader, setShowHeader] = React.useState(false);
  const open = Boolean(anchorEl);
  const destination = Boolean(anchorE2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDestinationClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const getChanges = (e) => {
    setTripType(e.target.value);
  };

  const _handleredirect = () => {
    if (!departureSelected) {
      return setError({
        ...error,
        error: true,
        errormessage: "please departure location",
        depature: true,
      });
    }
    if (!ariveSelected) {
      return setError({
        ...error,
        error: true,
        errormessage: "please arrival location",
        arive: true,
      });
    }
    if (!selectedDate) {
      return setError({
        ...error,
        error: true,
        errormessage: "please provide depart date",
        date: true,
      });
    }
    setError({
      ...error,
      error: true,
      errormessage: "please select how many passanger",
      passanger: true,
    });
    if (passanger.adult || passanger.child || passanger.infants) {
      let month = months.findIndex((state) =>
        state.toLowerCase().startsWith(selectedDate.split("-")[1].toLowerCase())
      );
      console.log(month);
      let link = `/in/search/${departureSelected.slug}${
        selectedDate.split("-")[2]
      }${selectedDate.split("-")[1]}${ariveSelected.slug}${
        passanger.adult + passanger.child + passanger.infants
      }00?currency=usd&language=en&locale=en&marker=496396.Zzd4c7f209a7df4067800d1ae-496396&with_request=true`;
      window.location.href = link;
    }
  };

  const _handleExchange = () => {
    let temp = departureSelected;
    setDepatureSelected(ariveSelected);
    setAriveSelected(temp);
    return;
  };

  const _handleSearch = async (e) => {
    try {
      const request = await axios.get(
        `https://suggest.travelpayouts.com/search?service=aviasales&term=${e.target.value}&locale=en`
      );
      setAirPortSearch(request.data);
    } catch (err) {
      setAirPortSearch([]);
    }
  };
  return (
    <>
      {showBanner && (
        <div className="fixed w-full h-full flex items-start justify-center z-50">
          <div className="absolute w-full h-full bg-white/60"></div>
          <div className="max-md:w-[550px] w-11/12 max-md:h-[550px] relative max-sm:w-11/12 flex items-center justify-start flex-col ">
            <div
              className="absolute bg-black -right-5 top-2 flex items-center justify-center rounded-full z-50 w-8 h-8"
              onClick={() => {
                setShowBanner(false);
              }}
            >
              <MdOutlineClose color="white" />
            </div>
            <a
              href="tel:+18338352670"
              className="relative z-10 h-full bg-[#ffefea] w-full mt-4 rounded-lg py-4"
            >
              <div className="flex items-center justify-between  px-4 ">
                <img src={LogoBlack} alt="" className="w-24" />
                <div>
                  <div className="w-full bg-white h-full  rounded-full flex items-center justify-center pr-3 border-y border-y-black/50 border-r border-r-black/50">
                    <div className="bg-[#fc6c3a] w-9 h-9 flex items-center justify-center rounded-full">
                      <div className="call_icon">
                        <PiPhoneCallFill
                          color="white"
                          size={22}
                          id="call_icon"
                        />
                      </div>
                    </div>
                    <div className="ml-3  h-full">
                      <div className="text-black font-bold text-xs">
                        +1833-835-2670
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full py-2 bg-black my-4 px-4 text-center text-white/70">
                Get Discounted Flights on Call
              </div>
              <div className="flex items-center justify-between px-5">
                <img src={Plane} alt="" className="w-48" />
                <div className="flex flex-wrap max-md:flex-col">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Delta_logo.svg/768px-Delta_logo.svg.png"
                    alt=""
                    className="mb-4 w-52 max-md:w-24"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/e/e0/United_Airlines_Logo.svg/450px-United_Airlines_Logo.svg.png"
                    alt=""
                    className="mb-4 w-52 max-md:w-24"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/thumb/2/23/American_Airlines_logo_2013.svg/450px-American_Airlines_logo_2013.svg.png"
                    alt=""
                    className="mb-4 w-52 max-md:w-24"
                  />
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Southwest_Airlines_logo_2014.svg/330px-Southwest_Airlines_logo_2014.svg.png"
                    alt=""
                    className="mb-4 w-52 max-md:w-24"
                  />
                </div>
              </div>
              <div className="flex items-center w-full flex-wrap px-4 mt-3">
                <div className="flex w-1/2 pr-2 mb-2 ">
                  <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                    New booking
                  </div>
                </div>
                <div className="flex w-1/2 pl-2 mb-2 ">
                  <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                    Changes
                  </div>
                </div>
                <div className="flex w-1/2 pr-2 mb-2 ">
                  <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                    Cancellation
                  </div>
                </div>
                <div className="flex w-1/2 pl-2 mb-2 ">
                  <div className="w-full bg-[#fc6c3a] py-2 text-sm font-semibold rounded-full text-center">
                    Custom services
                  </div>
                </div>
              </div>
              <div className="w-full mt-6   px-4 text-start text-black/70">
                Get 24/7 assistance
              </div>
              <div className="flex items-center justify-center mt-2 px-5">
                {/* <img
                  src={Booking}
                  alt=""
                  className="w-32 h-32 rounded-full obje"
                /> */}
              </div>
              <div className="w-full flex items-center justify-center mt-6   px-4 text-start text-black/70">
                <div className="">
                  <div className="w-full bg-[#fc6c3a] h-full  rounded-full flex items-center justify-center pr-3 border-y border-y-black/50 border-r border-r-black/50">
                    <div className=" w-16 h-16 bg-white flex items-center justify-center rounded-full">
                      <div className="call_icon">
                        <PiPhoneCallFill
                          color="#fc6c3a"
                          size={28}
                          id="call_icon"
                        />
                      </div>
                    </div>
                    <div className="ml-3  h-full font-bold text-black text-lg">
                      <div className="text-black text-xs font-thin">
                        Call now and book
                      </div>
                      +1833-835-2670
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="font-bold text-center mt-3">
                toll free call and 24/7 helplines{" "}
              </div>
            </a>
          </div>
        </div>
      )}
      <a
        href="tel:+18338352670"
        className=" w-full z-50 hidden items-center justify-center h-12 px-2 max-md:flex bg-[#fc6c3a]"
        aria-label="Call and get unpublished Flight Deals at +1833-835-2670"
      >
        <div className="w-full bg-[#fc6c3a] h-full  rounded-lg flex items-center justify-center px-3">
          <div className="bg-[#fc6c3a] w-9 h-9 flex items-center justify-center rounded-md">
            <div className="call_icon">
              <PiPhoneCallFill color="white" size={28} id="call_icon" />
            </div>
          </div>
          <div className="ml-3">
            <div className="text-white font-bold text-base">
              Call now +1833-835-2670
            </div>
          </div>
        </div>
      </a>
      <a
        href="tel:+18338352670"
        className="fixed bottom-2 w-full z-50 hidden items-center justify-center h-20 px-2 max-md:flex"
        aria-label="Call and get unpublished Flight Deals at +1833-835-2670"
      >
        <div className="w-full bg-[#fc6c3a] h-full rounded-lg flex items-center px-3">
          <div className="bg-[#fc6c3a] w-14 h-14 flex items-center justify-center rounded-md">
            <div className="call_icon">
              <PiPhoneCallFill color="white" size={32} id="call_icon" />
            </div>
          </div>
          <div className="ml-3">
            <div className="text-white/80 font-semibold text-sm">
              Call & get unpublished Flight Deals
            </div>
            <div className="text-2xl font-bold text-white">+1833-835-2670</div>
          </div>
        </div>
      </a>
      <div
        style={{
          backgroundImage: `
          linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 1) 100%),
          url('https://images.pexels.com/photos/161815/santorini-oia-greece-water-161815.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')
        `,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="px-16  max-[1250px]:px-16 py-4 relative mb-20 max-md:px-10 h-auto w-full max-[1250px]:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-0"
      >
        <>
          <div className="flex items-center justify-between sticky bg-transparent px-4">
            <div className="flex items-center">
              <div
                className="mr-4 hidden max-[1084px]:block"
                onClick={() => {
                  setShowHeader(!showHeader);
                }}
              >
                <RxHamburgerMenu color={"rgba(255,255,255,0.7)"} size={23} />
              </div>

              <img src={LogoWhite} alt="" className="w-44" />
            </div>

            <div className="flex items-center max-[1084px]:hidden">
              <Link to="/">
                <Button
                  id="basic-button"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    textTransform: "capitalize",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                >
                  Home
                </Button>
              </Link>
              <div className="">
                <Button
                  id="basic-button"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    textTransform: "capitalize",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  About Company
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link to="/in/about">
                    <MenuItem
                      onClick={handleClose}
                      className="px-4 py-2 hover:bg-gray-200"
                    >
                      About Us
                    </MenuItem>
                  </Link>
                  <Link to="/in/terms">
                    <MenuItem onClick={handleClose}>
                      Terms & Conditions
                    </MenuItem>
                  </Link>
                  <Link to="/in/policy">
                    <MenuItem onClick={handleClose}>Privacy Policy</MenuItem>
                  </Link>
                </Menu>
              </div>
              <div className="text-base text-white/70 mx-4 font-light">
                <Button
                  id="destination-menu"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    textTransform: "capitalize",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                  aria-controls={open ? "destination-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleDestinationClick}
                >
                  Top Destinations
                </Button>
                <Menu
                  id="destination-menu"
                  anchorEl={anchorE2}
                  open={destination}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "destination-menu",
                  }}
                >
                  {destinations.map((destination, index) => (
                    <Link
                      to={`/in/destination/${destination.title
                        .split(" ")
                        .join("")
                        .toLowerCase()}`}
                    >
                      <MenuItem
                        key={index}
                        onClick={handleClose}
                        className="px-4 py-2 hover:bg-gray-200"
                      >
                        {destination.title}
                      </MenuItem>
                    </Link>
                  ))}
                </Menu>
              </div>
              <Button
                id="basic-button"
                style={{
                  color: "rgba(255,255,255,0.7)",
                  textTransform: "capitalize",
                  fontFamily: `Rethink Sans", sans-serif !important`,
                }}
              >
                Blogs
              </Button>
              <Link to="/in/contact">
                <Button
                  id="basic-button"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    textTransform: "capitalize",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                >
                  Contact
                </Button>
              </Link>
            </div>
            <div className="flex items-center">
              <a
                href="tel:+18338352670"
                className="bg-[#fc6c3a] py-3 px-5 rounded-md text-white text-lg flex items-center justify-center"
              >
                <img src={Custom} className="w-9" alt="" />
                <span className="max-md:hidden font-bold ml-2">
                  Toll Free +1833-835-2670
                </span>
              </a>
            </div>
          </div>
          {showHeader && (
            <div
              className={`h-screen w-full  ${"rgba(255,255,255,0.7)"} fixed top-0 right-0 left-0 bottom-0 z-50 py-4 px-6 flex flex-col`}
            >
              <div className="flex items-center mb-6">
                <div
                  className="mr-4"
                  onClick={() => {
                    setShowHeader(!showHeader);
                  }}
                >
                  <MdClose color={"rgba(255,255,255,0.7)"} size={23} />
                </div>
                <span
                  className={`text-2xl ${"text-white"} font-semibold`}
                ></span>
              </div>
              <Link to="/" className="my-5 w-full">
                <Button
                  id="basic-button"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    width: "100%",
                    textTransform: "capitalize",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                >
                  Home
                </Button>
              </Link>
              <div className="my-5">
                <Button
                  id="basic-button"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    textTransform: "capitalize",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                    width: "100%",
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  About Company
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link to="/in/about">
                    <MenuItem
                      onClick={handleClose}
                      className="px-4 py-2 hover:bg-gray-200"
                    >
                      About Us
                    </MenuItem>
                  </Link>
                  <Link to="/in/terms">
                    <MenuItem onClick={handleClose}>
                      Terms & Conditions
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={handleClose}>Privacy Policy</MenuItem>
                </Menu>
              </div>
              <div className="text-base text-white/70 font-light my-5">
                <Button
                  id="destination-menu"
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    textTransform: "capitalize",
                    width: "100%",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                  aria-controls={open ? "destination-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleDestinationClick}
                >
                  Top Destinations
                </Button>
                <Menu
                  id="destination-menu"
                  anchorEl={anchorE2}
                  open={destination}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "destination-menu",
                  }}
                >
                  {destinations.map((destination, index) => (
                    <Link
                      to={`/in/destination/${destination.title
                        .split(" ")
                        .join("")
                        .toLowerCase()}`}
                    >
                      <MenuItem
                        key={index}
                        onClick={handleClose}
                        className="px-4 py-2 hover:bg-gray-200"
                      >
                        {destination.title}
                      </MenuItem>
                    </Link>
                  ))}
                </Menu>
              </div>
              <Button
                id="basic-button"
                style={{
                  color: "rgba(255,255,255,0.7)",
                  textTransform: "capitalize",
                  fontFamily: `Rethink Sans", sans-serif !important`,
                  width: "100%",
                }}
              >
                Blogs
              </Button>
              <Link to="/in/contact" className="my-5">
                <Button
                  id="basic-button"
                  style={{
                    color: "rgba(255,255,255,0.7)",

                    textTransform: "capitalize",
                    width: "100%",
                    fontFamily: `Rethink Sans", sans-serif !important`,
                  }}
                >
                  Contact
                </Button>
              </Link>
            </div>
          )}
        </>
        <div
          className={`flex-col  px-12 max-md:px-4 py-20 max-[1250px]:pt-0 mt-6 flex items-start justify-start  h-full max-md:pb-7 relative`}
        >
          {error.error && (
            <div className="fixed top-24 right-6  bg-white shadow-lg z-50 px-8 py-4">
              {error.errormessage}
            </div>
          )}
          <div className="w-full ">
            <div className="text-[#fc6c3a] font-semibold text-xl ">
              Find Your Perfect Flight
            </div>
            <div className="text-6xl font-bold max-[1250px]:w-full max-lg:text-5xl text-white w-3/5 mt-3 max-md:text-4xl max-md:w-full">
              Find Your Perfect Flight deals, Guaranteed Low Price
            </div>
            <div className="text-lg  text-white w-3/5 mt-5 max-md:w-full">
              Your adventure starts here. Discover incredible destinations.
              Search flights, compare prices, and book effortlessly. Your
              perfect getaway awaits.
            </div>
            <div className="w-full px-6 pb-7 bg-white mt-14 rounded-md absolute max-[1250px]:relative shadow-md -bottom-52 max-[1250px]:-bottom-0 left-0   max-md:relative max-md:px-2">
              <div className="flex items-center pt-6 px-4">
                <div className="mr-4">
                  <input
                    onChange={getChanges}
                    type="radio"
                    id="one-way"
                    name="trip"
                    value="one-way"
                    checked={tripType === "one-way"}
                    aria-label="One way trip option"
                  />
                  <label htmlFor="one-way" className="ml-2">
                    One Way
                  </label>
                </div>
                <div>
                  <input
                    onChange={getChanges}
                    type="radio"
                    id="round-trip"
                    name="trip"
                    checked={tripType === "round-trip"}
                    value="round-trip"
                    aria-label="Round trip option"
                  />
                  <label htmlFor="round-trip" className="ml-2">
                    Round Trip
                  </label>
                </div>
              </div>
              <div className="flex items-center pb-4 w-full justify-between max-md:flex-col max-[1250px]:flex-wrap max-[1250px]">
                <div className="w-1/5 py-3 border-l-white/15 border-r max-md:border-r-none max-md:border-l max-md:border-l-white/15 px-4  max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
                  <span className="text-base font-semibold text-black ">
                    From
                  </span>
                  <div
                    onClick={() => {
                      setHandleShowDepature(!handleShowDeparture);
                      setHandleShowArive(false);
                      setShowPassager(false);
                    }}
                    className={`${
                      error.depature
                        ? "border-2 border-red-600"
                        : "max-md:border border-black"
                    } w-full  select-none  rounded-md mt-1.5 py-2 px-4`}
                  >
                    <div className="flex items-center">
                      <span className="mr-5 max-sm:flex max-sm:flex-wrap">
                        {departureSelected ? (
                          <span>
                            <div className="text-5xl font-bold text-black max-md:text-sm">
                              {departureSelected?.slug}
                            </div>
                            <div className="text-sm text-black/70">
                              {departureSelected?.title}
                            </div>
                          </span>
                        ) : (
                          <div className="text-xl  text-black">Where from?</div>
                        )}
                      </span>{" "}
                    </div>
                  </div>
                  {handleShowDeparture ? (
                    <div className="absolute top-20 max-md:fixed max-md:top-0 max-md:left-0 max-md:bottom-0 max-md:right-0 max-md:bg-black max-md:max-h-screen max-md:z-50 w-full bg-white max-h-72 overflow-y-scroll z-10">
                      <div className="sticky top-0 h-12 flex items-center justify-center max-md:border-b max-md:border-b-white/15 max-md:bg-black">
                        <div
                          className="text-red-300 max-md:block hidden px-4"
                          onClick={() => {
                            setError({
                              ...error,
                              depature: false,
                            });
                            setAirPortSearch([]);
                            setHandleShowDepature(false);
                          }}
                        >
                          <IoArrowBackOutline />
                        </div>
                        <input
                          onChange={_handleSearch}
                          type="text"
                          placeholder="search airport..."
                          className="w-full h-full border border-black/10 px-4 max-md:bg-black max-md:text-white max-md:outline-none text-base"
                        />
                      </div>
                      {airPortSearch.map((airport, index) => (
                        <div
                          onClick={() => {
                            setDepatureSelected(airport);
                            setError({
                              ...error,
                              depature: false,
                            });
                            setAirPortSearch([]);
                            setHandleShowDepature(false);
                          }}
                          className="py-3 px-6 cursor-pointer max-md:text-black/70 flex items-center"
                        >
                          <div className="py-2 px-3 text-xs mr-2 hidden max-md:block text-black rounded-md bg-[#fc6c3a]">
                            {airport.slug}
                          </div>
                          {airport.title}
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div
                    onClick={_handleExchange}
                    className="h-12 w-12 rounded-full max-md:hidden border  absolute top-8 -right-6 max-md:-left-5 bg-black border-white/40 flex items-center justify-center"
                  >
                    <HiOutlineSwitchVertical color="#fc6c3a" />
                  </div>
                </div>
                <div className="w-1/5 py-4 max-sm:py-0 border-r-black/15 max-md:border-r-none max-md:px-4 max-md:border-l max-md:border-l-white/15 border-r px-12 max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
                  <span className="text-base font-semibold text-black">To</span>
                  <div
                    onClick={() => {
                      setHandleShowDepature(false);
                      setHandleShowArive(!handleShowArive);
                      setShowPassager(false);
                    }}
                    className={`${
                      error.arive
                        ? "border-2 border-red-600"
                        : "max-md:border border-black/80"
                    } w-full  select-none  rounded-md mt-1.5 py-2 px-4`}
                  >
                    <div className="flex items-center">
                      <span className="mr-5">
                        {ariveSelected ? (
                          <span>
                            <div className="text-5xl font-bold text-black max-md:text-sm">
                              {ariveSelected?.slug}
                            </div>
                            <div className="text-sm text-black/70">
                              {ariveSelected?.title}
                            </div>
                          </span>
                        ) : (
                          "select flight"
                        )}
                      </span>{" "}
                    </div>
                  </div>
                  {handleShowArive ? (
                    <div className="absolute top-30 max-md:fixed max-md:top-0 max-md:left-0 max-md:bottom-0 max-md:right-0 max-md:bg-black max-md:max-h-screen max-md:z-50 w-full bg-white max-h-72 overflow-y-scroll z-10">
                      <div className="sticky top-0 h-12 flex items-center justify-center max-md:border-b max-md:border-b-white/10 max-md:bg-black">
                        <div
                          className="text-red-300 max-md:block hidden px-4"
                          onClick={() => {
                            setAirPortSearch([]);
                            setError({
                              ...error,
                              arive: false,
                            });
                            setHandleShowArive(false);
                          }}
                        >
                          <IoArrowBackOutline />
                        </div>
                        <input
                          onChange={_handleSearch}
                          type="text"
                          placeholder="search airport..."
                          className="w-full h-full border border-black/90 px-4 max-md:bg-black max-md:text-white max-md:outline-none text-base"
                        />
                      </div>
                      {airPortSearch.map((airport, index) => (
                        <div
                          onClick={() => {
                            setAriveSelected(airport);
                            setAirPortSearch([]);
                            setError({
                              ...error,
                              arive: false,
                            });
                            setHandleShowArive(false);
                          }}
                          className="py-3 px-6 cursor-pointer max-md:text-white/70 flex items-center"
                        >
                          <div className="py-2 px-3 text-xs mr-2 hidden max-md:block text-black rounded-md bg-[#fc6c3a]">
                            {airport.slug}
                          </div>
                          {airport.title}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div
                  onClick={() => document.getElementById("date-picker").click()}
                  className="w-1/5 py-3 px-12 border-r-black/15 max-md:px-4 max-md:border-l max-md:border-l-white/15 border-r max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2"
                >
                  <span className="text-base text-black font-semibold">
                    Departure
                  </span>
                  <div
                    className={` ${
                      error.date
                        ? "border-2 border-red-600"
                        : "max-md:border border-black/70"
                    } w-full   relative rounded-md mt-1.5 flex items-center justify-between p-2`}
                  >
                    <span>
                      <div className="text-5xl font-bold text-black max-sm:text-base">
                        {selectedDate.split("-")[2]}
                      </div>
                      <div className="text-sm  text-black/70">
                        {" "}
                        {months[parseInt(selectedDate.split("-")[1] - 1)]}{" "}
                        {selectedDate.split("-")[0]}
                      </div>
                    </span>
                    <DatePicker
                      id="date-picker"
                      className="hidden"
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        if (date) {
                          console.log(date.getMonth()); // Logs the month (0-based)
                          console.log(
                            `${date.getFullYear()}-${
                              date.getMonth() + 1 < 10
                                ? `0${date.getMonth() + 1}`
                                : date.getMonth() + 1
                            }${date.getDate()}`
                          );
                          setSelectedDate(
                            `${date.getFullYear()}-${
                              date.getMonth() + 1 < 10
                                ? `0${date.getMonth() + 1}`
                                : date.getMonth() + 1
                            }-${date.getDate()}`
                          );
                        }
                      }}
                      popperPlacement="bottom" // Controls where the calendar pops up
                    />
                  </div>
                </div>
                {tripType === "round-trip" && (
                  <div
                    onClick={() =>
                      document.getElementById("date-picker-return").click()
                    }
                    className="w-1/5 py-3 px-12 border-r-black/15 max-md:px-4 max-md:border-l max-md:border-l-white/15 border-r max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2"
                  >
                    <span className="text-base text-black font-semibold">
                      Return
                    </span>
                    <div
                      className={` ${
                        error.date
                          ? "border-2 border-red-600"
                          : "max-md:border border-black/80"
                      } w-full  relative rounded-md mt-1.5 flex items-center justify-between p-2`}
                    >
                      <span>
                        <div className="text-5xl font-bold text-black max-sm:text-base">
                          {selectedReturnDate.split("-")[2]}
                        </div>
                        <div className="text-sm text-black/70">
                          {" "}
                          {
                            months[
                              parseInt(selectedReturnDate.split("-")[1] - 1)
                            ]
                          }{" "}
                          {selectedReturnDate.split("-")[0]}
                        </div>
                      </span>
                      <DatePicker
                        id="date-picker-return"
                        className="hidden"
                        dateFormat="dd-MM-yyyy"
                        onChange={(date) => {
                          if (date) {
                            console.log(date.getMonth()); // Logs the month (0-based)
                            console.log(
                              `${date.getFullYear()}-${
                                date.getMonth() + 1 < 10
                                  ? `0${date.getMonth() + 1}`
                                  : date.getMonth() + 1
                              }-${date.getDate()}`
                            );
                            setSelectedReturnDate(
                              `${date.getFullYear()}-${
                                date.getMonth() + 1 < 10
                                  ? `0${date.getMonth() + 1}`
                                  : date.getMonth() + 1
                              }-${date.getDate()}`
                            );
                          }
                        }}
                        popperPlacement="bottom" // Controls where the calendar pops up
                      />
                    </div>
                  </div>
                )}
                <div className="w-1/5 py-3 px-12 border-r-white/15 max-md:px-4 max-md:border-l max-md:border-l-white/15 border-r max-md:w-full max-md:mb-3 relative max-[1250px]:w-1/2">
                  <span className="text-base text-black font-semibold">
                    Passanger
                  </span>
                  <div
                    className={`${
                      error.arive
                        ? "border-2 border-red-600"
                        : "max-md:border border-black/70"
                    } py-1 w-full   rounded-md mt-1.5  relative  p-2`}
                  >
                    <div
                      className="w-full h-full "
                      onClick={() => {
                        setHandleShowDepature(false);
                        setHandleShowArive(false);
                        setShowPassager(!showPassager);
                      }}
                    >
                      <h1 className="text-5xl font-bold text-black max-sm:text-base">
                        {parseInt(passanger.child) +
                          parseInt(passanger.adult) +
                          parseInt(passanger.infants)}
                      </h1>
                      <div className="flex items-center text-black/40">
                        <span className=" mr-5">
                          {passanger.adult} adult , {passanger.child} children,{" "}
                          {passanger.infants} Infants
                        </span>
                        <IoIosArrowDropdownCircle color="rgba(0,0,0,0.8)" />
                      </div>
                    </div>
                    {showPassager ? (
                      <div className="absolute top-12 left-0 w-72 bg-white  shadow-md max-h-72 overflow-y-scroll px-4 z-50">
                        <div className="flex items-center justify-between py-2">
                          <div>
                            Adult
                            <div className="text-xs text-black/80">
                              (12+ years)
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                              className="mr-2"
                              onClick={() => {
                                if (passanger.adult > 0) {
                                  setPassanger((prevState) => ({
                                    ...prevState,
                                    adult: prevState.adult - 1,
                                  }));
                                }
                              }}
                            >
                              <FaMinus />
                            </div>
                            <div className="p-2 mx-2  rounded-full ">
                              {passanger.adult}
                            </div>
                            <div
                              className="p-2 ml-2 border border-black rounded-full "
                              onClick={() => {
                                setPassanger((prevState) => ({
                                  ...prevState,
                                  adult: prevState.adult + 1,
                                }));
                              }}
                            >
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between py-2">
                          <div>
                            Children
                            <div className="text-xs text-black/80">
                              (2-12 years)
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                              className="mr-2"
                              onClick={() => {
                                if (passanger.child > 0) {
                                  setPassanger((prevState) => ({
                                    ...prevState,
                                    child: prevState.child - 1,
                                  }));
                                }
                              }}
                            >
                              <FaMinus />
                            </div>
                            <div className="p-2 mx-2  rounded-full ">
                              {passanger.child}
                            </div>
                            <div
                              className="p-2 ml-2 border border-black rounded-full "
                              onClick={() => {
                                setPassanger((prevState) => ({
                                  ...prevState,
                                  child: prevState.child + 1,
                                }));
                              }}
                            >
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between py-2">
                          <div>
                            Infants
                            <div className="text-xs text-black/80">
                              (below 2 yrs)
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div
                              className="mr-2"
                              onClick={() => {
                                if (passanger.infants > 0) {
                                  setPassanger((prevState) => ({
                                    ...prevState,
                                    infants: prevState.infants - 1,
                                  }));
                                }
                              }}
                            >
                              <FaMinus />
                            </div>
                            <div className="p-2 mx-2  rounded-full ">
                              {passanger.infants}
                            </div>
                            <div
                              className="p-2 ml-2 border border-black rounded-full "
                              onClick={() => {
                                setPassanger((prevState) => ({
                                  ...prevState,
                                  infants: prevState.infants + 1,
                                }));
                              }}
                            >
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center w-full absolute max-md:relative -bottom-5">
                <div
                  onClick={_handleredirect}
                  className="py-3 px-7 bg-black text-white text-xl font-bold flex items-center justify-center rounded-full max-md:rounded-md max-md:w-full"
                >
                  Search flights <FaArrowRight color="white" className="ml-2" />
                </div>
              </div>
              {/* <div className="hidden max-md:block mt-3">
                <a href="tel:+18338352670">
                  <img src={CallBanner} alt="" className="w-full" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-52 pb-20 px-24 flex items-start justify-between max-lg:flex-wrap max-md:px-12 max-sm:px-5">
        {quality.map((quality) => (
          <div className="flex flex-col items-center w-1/3 max-md:w-full px-4 max-md:mb-9 max-lg:w-1/2 max-lg:mb-9">
            <div>
              <quality.icon size={26} />
            </div>
            <div className="text-2xl font-bold mt-4">{quality.title}</div>
            <div className="text-black/70 mt-2 text-center">
              {quality.description}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full py-20 px-24 flex items-start max-lg:flex-wrap max-lg:pt-4 max-lg:px-12 max-sm:px-4">
        <div className="w-1/2 max-lg:w-full">
          <img
            src="https://farewingtravels.com/wp-content/uploads/2023/07/gallery_3_3.jpg"
            className="w-full h-[500px] rounded-md object-cover object-top max-lg:h-[200px]"
            alt=""
          />
        </div>
        <div className="w-1/2 ml-7 max-lg:w-full max-lg:ml-0 max-lg:mt-6">
          <div className="text-5xl font-semibold mb-2 max-md:text-3xl">
            About goFlyCheap
          </div>
          <div className="text-lg text-black/70 text-justify font-thin mb-2">
            goFlyCheap is your ultimate destination for budget-friendly travel.
            We're a dedicated platform committed to finding you the cheapest
            flights without compromising on quality or service. Unlike other
            travel sites that might prioritize certain airlines, we scour the
            market to bring you a comprehensive range of options, ensuring you
            always get the best deal.
          </div>
          <div className="text-lg text-black/70 text-justify font-thin mb-2">
            Our mission is simple: to make air travel affordable for everyone.
            Whether you're a seasoned traveler or a first-time flyer, goFlyCheap
            is your trusted partner in finding the perfect flight. We believe
            that everyone deserves to experience the joy of travel, and we're
            here to make it happen.
          </div>
        </div>
      </div>
      <div className="py-14 px-24 max-lg:px-12 max-sm:px-4">
        <h1 className="font-bold text-4xl">Discover Your Next Adventure</h1>
        <p className="text-lg text-black/80">
          Explore the world's most captivating cities and countries.
        </p>
        <div className="flex items-center flex-wrap my-6">
          {places.map((place, index) => (
            <div
              className={`px-3 rounded-md max-md:w-full ${
                [2, 3].includes(index + 1)
                  ? "w-3/5 max-md:w-full"
                  : "w-2/5 max-md:w-full"
              }`}
            >
              <div
                className="w-full h-72 rounded-md relative max-md:h-52 flex items-end justify-center my-2 px-6"
                style={{
                  backgroundImage: `
                    linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0% ,rgba(0, 0, 0, 0.3)),
                    url('${place.image}')
                  `,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="relative w-full h-20 mb-1 ">
                  <div className="w-full h-full rounded-md z-10 relative py-2 px-3 font-semibold capitalize">
                    <div className="text-xl text-white">{place.place}</div>
                    <div className="text-base text-white/80">{place.title}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white w-full pt-20">
        <div className="w-full text-center text-5xl font-bold text-black flex justify-center">
          <div className="w-3/5 max-md:w-full max-md:px-12">
            {" "}
            Your Next Adventure Starts{" "}
            <span className="text-[#fc6c3a]">Here</span>
          </div>
        </div>
        <div className="flex items-start w-full mt-14 h-[520px] max-md:h-auto max-md:flex-wrap">
          <div className="w-1/2 flex flex-col h-full max-md:w-full">
            <div
              className="w-full h-full bg-black flex items-end justify-start px-7 py-8 max-md:py-20 "
              style={{
                backgroundImage: `
              linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0% ,rgba(0, 0, 0, 0.4)),
              url('https://images.pexels.com/photos/2033343/pexels-photo-2033343.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')
            `,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="text-white">
                <h1 className="text-xl">Destionation available</h1>
                <span className="text-[#fc6c3a] text-3xl  mt-1 font-bold">
                  400+
                </span>
              </div>
            </div>
          </div>
          <div
            className="w-1/2 max-md:w-full flex flex-col h-full items-center justify-center max-md:py-20"
            style={{
              backgroundImage: `
              linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0% ,rgba(0, 0, 0, 0.4)),
              url('https://images.pexels.com/photos/1547813/pexels-photo-1547813.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')
            `,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="text-3xl font-semibold text-white w-2/3 text-center">
              Discover Your Ideal Flight: Search, Compare, and Book
            </div>
            <div
              className="w-44 h-12 shadow-lg bg-black text-lg text-white mt-5 flex items-center justify-center rounded-md"
              onClick={() => {
                window.scroll({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Book now
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
